/**
 *
 * @param elementRef  dom 节点
 * @param isOpen
 * @returns
 */

function useClickOutside(elementRef: Ref<null | HTMLElement>, isOpen?: Ref<boolean>) {
	const isClickOutside = ref(false);

	function handler(e: MouseEvent) {
		if (elementRef.value) {
			if (!elementRef.value.contains(e.target as HTMLElement)) {
				isClickOutside.value = true;
				if (isOpen) {
					if (isOpen.value) isOpen.value = false;
				}
			} else {
				isClickOutside.value = false;
			}
		}
	}

	onMounted(() => {
		document.addEventListener("click", handler);
	});

	onUnmounted(() => {
		document.removeEventListener("click", handler);
	});

	return isClickOutside;
}

export default useClickOutside;
